export const getOffering = /* GraphQL */ `
  query GetOffering($id: ID!) {
    getOffering(id: $id) {
      id
      cmAddress
      home {
        id
        name
        status
        address
        city
        state
        zipCode
        numBeds
        numBaths
        squareFt
        totalSupply
        remainingSupply
        description
        images
        irr
        features {
          items {
            id
            name
            value
            createdAt
            updatedAt
            homeFeaturesId
          }
          nextToken
        }
        coordinate {
          id
          longitude
          latitude
          createdAt
          updatedAt
        }
        market {
          id
          name
          medPrice
          avgRent
          popGrowth
          description
          image
          createdAt
          updatedAt
        }
        financialProjection {
          id
          totalValue
          assetValue
          closingFee
          reserve
          totalReturn
          projAppr
          avgROI
          capRate
          mGrossRent
          vacancy
          utilities
          mngmtFee
          franchiseFee
          eiRatio
          totalCost
          cashFlow
          propertyTax
          propertyInsurance
          createdAt
          updatedAt
        }
        documents {
          items {
            id
            name
            value
            createdAt
            updatedAt
            homeDocumentsId
          }
          nextToken
        }
        buyingProcess {
          items {
            id
            date
            name
            description
            createdAt
            updatedAt
            homeBuyingProcessId
          }
          nextToken
        }
        createdAt
        updatedAt
        homeCoordinateId
        homeMarketId
        homeFinancialProjectionId
      }
      status
      totalSupply
      remainingSupply
      pricePerShare
      minInvestment
      maxInvestment
      projectedValue
      nftArt
      date
      comments {
        items {
          id
          offeringId
          firstName
          text
          createdAt
          updatedAt
          offeringCommentsId
          owner
        }
        nextToken
      }
      likesDislikes {
        items {
          id
          type
          createdAt
          updatedAt
          offeringLikesDislikesId
          owner
        }
        nextToken
      }
      views
      entity
      createdAt
      updatedAt
      offeringHomeId
    }
  }
`;
export const getPublicOffering = /* GraphQL */ `
  query GetOffering($id: ID!) {
    getOffering(id: $id) {
      id
      home {
        id
        name
        status
        address
        city
        state
        zipCode
        numBeds
        numBaths
        squareFt
        totalSupply
        remainingSupply
        description
        images
        irr
        coordinate {
          id
          longitude
          latitude
          createdAt
          updatedAt
        }
        financialProjection {
          id
          totalValue
          assetValue
          closingFee
          reserve
          totalReturn
          projAppr
          avgROI
          capRate
          mGrossRent
          vacancy
          utilities
          mngmtFee
          franchiseFee
          eiRatio
          totalCost
          cashFlow
          propertyTax
          propertyInsurance
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        homeCoordinateId
        homeMarketId
        homeFinancialProjectionId
      }
      status
      totalSupply
      remainingSupply
      pricePerShare
      minInvestment
      maxInvestment
      projectedValue
      nftArt
      date
      views
      entity
      createdAt
      updatedAt
      offeringHomeId
    }
  }
`;

export const listOfferings = /* GraphQL */ `
  query ListOfferings(
    $filter: ModelOfferingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOfferings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cmAddress
        home {
          id
          name
          status
          address
          city
          state
          zipCode
          numBeds
          numBaths
          squareFt
          totalSupply
          remainingSupply
          description
          images
          coordinate {
            id
            longitude
            latitude
            createdAt
            updatedAt
          }
          irr
          createdAt
          updatedAt
          homeCoordinateId
          homeMarketId
          homeFinancialProjectionId
        }
        status
        totalSupply
        remainingSupply
        pricePerShare
        minInvestment
        maxInvestment
        projectedValue
        entity
        date
        createdAt
        updatedAt
        offeringHomeId
      }
      nextToken
    }
  }
`;
export const getHome = /* GraphQL */ `
  query GetHome($id: ID!) {
    getHome(id: $id) {
      id
      name
      status
      address
      city
      state
      zipCode
      numBeds
      numBaths
      squareFt
      totalSupply
      remainingSupply
      description
      images
      irr
      features {
        items {
          id
          name
          value
          createdAt
          updatedAt
          homeFeaturesId
        }
        nextToken
      }
      coordinate {
        id
        longitude
        latitude
        createdAt
        updatedAt
      }
      market {
        id
        name
        medPrice
        avgRent
        popGrowth
        description
        image
        createdAt
        updatedAt
      }
      financialProjection {
        id
        totalValue
        assetValue
        closingFee
        reserve
        totalReturn
        projAppr
        avgROI
        capRate
        mGrossRent
        vacancy
        utilities
        mngmtFee
        franchiseFee
        eiRatio
        totalCost
        cashFlow
        createdAt
        updatedAt
      }
      documents {
        items {
          id
          name
          value
          createdAt
          updatedAt
          homeDocumentsId
        }
        nextToken
      }
      buyingProcess {
        items {
          id
          date
          name
          description
          createdAt
          updatedAt
          homeBuyingProcessId
        }
        nextToken
      }
      createdAt
      updatedAt
      homeCoordinateId
      homeMarketId
      homeFinancialProjectionId
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        offeringId
        owner
        firstName
        text
        createdAt
        updatedAt
        offeringCommentsId
      }
      nextToken
    }
  }
`;
export const listHomes = /* GraphQL */ `
  query ListHomes(
    $filter: ModelHomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        address
        city
        state
        zipCode
        numBeds
        numBaths
        squareFt
        totalSupply
        remainingSupply
        description
        images
        irr
        features {
          nextToken
        }
        coordinate {
          id
          longitude
          latitude
          createdAt
          updatedAt
        }
        market {
          id
          name
          medPrice
          avgRent
          popGrowth
          description
          image
          createdAt
          updatedAt
        }
        financialProjection {
          id
          totalValue
          assetValue
          closingFee
          reserve
          totalReturn
          projAppr
          avgROI
          capRate
          mGrossRent
          vacancy
          utilities
          mngmtFee
          franchiseFee
          eiRatio
          totalCost
          cashFlow
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyingProcess {
          nextToken
        }
        createdAt
        updatedAt
        homeCoordinateId
        homeMarketId
        homeFinancialProjectionId
      }
      nextToken
    }
  }
`;
export const getFeature = /* GraphQL */ `
  query GetFeature($id: ID!) {
    getFeature(id: $id) {
      id
      name
      value
      createdAt
      updatedAt
      homeFeaturesId
    }
  }
`;
export const listFeatures = /* GraphQL */ `
  query ListFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        value
        createdAt
        updatedAt
        homeFeaturesId
      }
      nextToken
    }
  }
`;
export const getCoordinate = /* GraphQL */ `
  query GetCoordinate($id: ID!) {
    getCoordinate(id: $id) {
      id
      longitude
      latitude
      createdAt
      updatedAt
    }
  }
`;
export const listCoordinates = /* GraphQL */ `
  query ListCoordinates(
    $filter: ModelCoordinateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoordinates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        longitude
        latitude
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMarket = /* GraphQL */ `
  query GetMarket($id: ID!) {
    getMarket(id: $id) {
      id
      name
      medPrice
      avgRent
      popGrowth
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const listMarkets = /* GraphQL */ `
  query ListMarkets(
    $filter: ModelMarketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarkets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        medPrice
        avgRent
        popGrowth
        description
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFinancialProjection = /* GraphQL */ `
  query GetFinancialProjection($id: ID!) {
    getFinancialProjection(id: $id) {
      id
      totalValue
      assetValue
      closingFee
      reserve
      totalReturn
      projAppr
      avgROI
      capRate
      mGrossRent
      vacancy
      utilities
      mngmtFee
      franchiseFee
      eiRatio
      totalCost
      cashFlow
      createdAt
      updatedAt
    }
  }
`;
export const listFinancialProjections = /* GraphQL */ `
  query ListFinancialProjections(
    $filter: ModelFinancialProjectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinancialProjections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        totalValue
        assetValue
        closingFee
        reserve
        totalReturn
        projAppr
        avgROI
        capRate
        mGrossRent
        vacancy
        utilities
        mngmtFee
        franchiseFee
        eiRatio
        totalCost
        cashFlow
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      name
      value
      createdAt
      updatedAt
      homeDocumentsId
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        value
        createdAt
        updatedAt
        homeDocumentsId
      }
      nextToken
    }
  }
`;
export const getBuyingStep = /* GraphQL */ `
  query GetBuyingStep($id: ID!) {
    getBuyingStep(id: $id) {
      id
      date
      name
      description
      createdAt
      updatedAt
      homeBuyingProcessId
    }
  }
`;
export const listBuyingSteps = /* GraphQL */ `
  query ListBuyingSteps(
    $filter: ModelBuyingStepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuyingSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        name
        description
        createdAt
        updatedAt
        homeBuyingProcessId
      }
      nextToken
    }
  }
`;
export const getInterest = /* GraphQL */ `
  query GetInterest($userId: ID!, $propertyId: ID!) {
    getInterest(userId: $userId, propertyId: $propertyId) {
      userId
      propertyId
      interest
      amount
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listInterests = /* GraphQL */ `
  query ListInterests(
    $userId: ID
    $propertyId: ModelIDKeyConditionInput
    $filter: ModelInterestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInterests(
      userId: $userId
      propertyId: $propertyId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        propertyId
        interest
        amount
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($userId: ID!, $offeringId: ID!) {
    getAccount(userId: $userId, offeringId: $offeringId) {
      userId
      offeringId
      author
      balance
      createdAt
      updatedAt
      accountOfferingId
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $userId: ID
    $offeringId: ModelIDKeyConditionInput
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccounts(
      userId: $userId
      offeringId: $offeringId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        offeringId
        author
        offering {
          id
          cmAddress
          status
          totalSupply
          remainingSupply
          pricePerShare
          minInvestment
          maxInvestment
          date
          createdAt
          updatedAt
          offeringHomeId
          home {
            images
            name
            city
            state
          }
        }
        transactions {
          nextToken
        }
        balance
        createdAt
        updatedAt
        accountOfferingId
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      author
      amount
      status
      type
      wallet
      createdAt
      updatedAt
      accountTransactionsUserId
      accountTransactionsOfferingId
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        amount
        status
        type
        wallet
        createdAt
        updatedAt
        accountTransactionsUserId
        accountTransactionsOfferingId
      }
      nextToken
    }
  }
`;
export const getNAWhitelist = /* GraphQL */ `
  query GetNAWhitelist($offeringId: ID!) {
    getNAWhitelist(offeringId: $offeringId) {
      offeringId
      whitelist
      count
      createdAt
      updatedAt
    }
  }
`;
export const listNAWhitelists = /* GraphQL */ `
  query ListNAWhitelists(
    $offeringId: ID
    $filter: ModelNAWhitelistFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNAWhitelists(
      offeringId: $offeringId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        offeringId
        whitelist
        count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvestTracking = /* GraphQL */ `
  query GetInvestTracking($id: ID!) {
    getInvestTracking(id: $id) {
      id
      userId
      propertyID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listInvestTrackings = /* GraphQL */ `
  query ListInvestTrackings(
    $filter: ModelInvestTrackingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvestTrackings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        propertyID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($userId: ID!, $propertyId: ID!) {
    getLike(userId: $userId, propertyId: $propertyId) {
      userId
      propertyId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $userId: ID
    $propertyId: ModelIDKeyConditionInput
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLikes(
      userId: $userId
      propertyId: $propertyId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        propertyId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getNews = /* GraphQL */ `
  query GetNews($id: ID!) {
    getNews(id: $id) {
      id
      title
      source
      image
      url
      date
      createdAt
      updatedAt
    }
  }
`;
export const listNews = /* GraphQL */ `
  query ListNews(
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        source
        image
        url
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      country
      state
      firstName
      lastName
      email
      isAccredited
      approved
      kycStatus
      accreditedStatus
      wallet
      walletType
      accreditationDoc
      owner
      status
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        country
        state
        firstName
        lastName
        email
        isAccredited
        approved
        kycStatus
        accreditedStatus
        wallet
        accreditationDoc
        owner
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPurchaseGroup = /* GraphQL */ `
  query GetPurchaseGroup($id: ID!) {
    getPurchaseGroup(id: $id) {
      id
      date
      members
      createdAt
      updatedAt
      offeringGroupsId
    }
  }
`;
export const listPurchaseGroups = /* GraphQL */ `
  query ListPurchaseGroups(
    $filter: ModelPurchaseGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        members
        createdAt
        updatedAt
        offeringGroupsId
      }
      nextToken
    }
  }
`;
