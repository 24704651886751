import { PublicKey, Transaction } from "@solana/web3.js";
import { CHAIN_NAMESPACES, SafeEventEmitterProvider } from "@web3auth/base";
import Web3Auth, {
  TORUS_LEGACY_NETWORK_TYPE,
} from "@web3auth/single-factor-auth";
import SolanaRpc from "../solanaRPC";
import {
  SolanaPrivateKeyProvider,
  TransactionOrVersionedTransaction,
} from "@web3auth/solana-provider";

export interface IEmbeddedWallet {
  publicKey: PublicKey | null;
  wallet: null;
  connected: boolean;
  disconnect: () => Promise<void>;
  sendTransaction: (transaction: Transaction) => Promise<string>;
  signMessage: (message: string) => Promise<Uint8Array>;
  signTransaction: <T extends TransactionOrVersionedTransaction>(
    transaction: T
  ) => Promise<T>;
  signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>;
}

export default class EmbeddedWallet implements IEmbeddedWallet {
  private web3auth: Web3Auth | null = null;
  private provider: SafeEventEmitterProvider | null = null;
  private clientId: string =
    "BE9z9At1qiPPYxmVSpT4GtUjRHD3QT5eNtjKvcns7Va7P6-UODAoQ-smxw6_61l2WzbJjgmoyz1Sv3VrxRgu9GY";
  private endpoint: string =
    "https://serene-soft-shard.solana-devnet.quiknode.pro/9ce758426ee69215dcf61cdb76071cc35d2be7b8/";
  private chainId: string = "0x3";
  private web3AuthNetwork: TORUS_LEGACY_NETWORK_TYPE = "testnet";
  private verifier: string = "inferno-custom";
  publicKey: PublicKey | null = null;
  wallet: null = null;
  connected = false;

  constructor() {
    if (
      process.env.REACT_APP_ENV === "prod" ||
      process.env.REACT_APP_ENV === "staging"
    ) {
      this.clientId =
        "BLJwuclK3d-Hqp0aCjMJjzRWKnCVa2l9aNJY1XVQ1aqviIUx8QRXbbpHgrhl4P8RapPvrI-jVWzh1QuerS3ZaqY";
      this.endpoint =
        "https://withered-fabled-yard.solana-mainnet.quiknode.pro/6a20ebb37d7af50aa93827c57fce68c793f054c2/";
      this.chainId = "0x1";
      this.web3AuthNetwork = "cyan";
      this.verifier = "inferno-custom-prod";
    }
  }

  static createWallet = async (user: any) => {
    const wallet = new EmbeddedWallet();
    await wallet.init();
    await wallet.connect(user);
    return wallet;
  };

  private init = async () => {
    try {
      const chainConfig = {
        chainNamespace: CHAIN_NAMESPACES.SOLANA,
        chainId: this.chainId,
        rpcTarget: this.endpoint,
        displayName: "",
        blockExplorer: "https://explorer.solana.com/",
        ticker: "SOL",
        tickerName: "Solana",
      };

      const web3auth = new Web3Auth({
        clientId: this.clientId,
        web3AuthNetwork: this.web3AuthNetwork,
      });

      this.web3auth = web3auth;

      const provider = new SolanaPrivateKeyProvider({
        config: {
          chainConfig,
        },
      });

      await web3auth.init(provider);
    } catch (error) {
      console.error(error);
    }
  };

  connect = async (user: any) => {
    if (!this.web3auth) {
      throw new Error("Web3Auth not initialized");
    }

    const web3authSfaprovider = await this.web3auth.connect({
      verifier: this.verifier,
      idToken: user.signInUserSession.idToken.jwtToken,
      verifierId: user.attributes.sub,
    });

    if (web3authSfaprovider) {
      this.provider = web3authSfaprovider;
      this.connected = true;
      const rpc = new SolanaRpc(this.provider);
      const publicKey = await rpc.getAccounts();
      this.publicKey = new PublicKey(publicKey[0]);
    }
  };

  disconnect = async () => {
    if (!this.web3auth) {
      throw new Error("Web3Auth not initialized");
    }
    this.connected = false;
    await this.web3auth.logout();
  };

  sendTransaction = async (transaction: Transaction) => {
    if (!this.provider) {
      throw new Error("Provider not initialized");
    }
    const rpc = new SolanaRpc(this.provider);
    return await rpc.sendTransaction(transaction);
  };

  signMessage = async (message: string) => {
    if (!this.provider) {
      throw new Error("Provider not initialized");
    }
    const rpc = new SolanaRpc(this.provider);
    return await rpc.signMessage(message);
  };

  signTransaction = async <T extends TransactionOrVersionedTransaction>(
    transaction: T
  ): Promise<T> => {
    if (!this.provider) {
      throw new Error("Provider not initialized");
    }
    const rpc = new SolanaRpc(this.provider);
    return (await rpc.signTransaction(transaction as Transaction)) as T; // Type casting may be needed depending on rpc.signTransaction type
  };

  signAllTransactions = async (transactions: Transaction[]) => {
    return Promise.reject(new Error("Not implemented"));
  };
}
