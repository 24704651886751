import { Typography } from "@mui/material";
import { useEffect } from "react";
import { HomebaseButton } from "../common/HomebaseButton";
import confirmation_svg from "../static/confirmation.svg";

const ConfirmationPage = () => {
  const params = new URLSearchParams(window.location.search);
  const name = params.get("name") || "your investment";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        textAlign: "center",
        maxWidth: "420px",
        height: "600px",
        margin: "auto",
        padding: "32px 8px",
      }}
    >
      <img
        src={confirmation_svg}
        alt="Confirmation graphic"
        width={"100%"}
        style={{ padding: "32px 0px" }}
      />
      <Typography variant="h5" className="semi-bold blue-text">
        You're almost there!
      </Typography>
      <br />
      <div style={{ textAlign: "justify", marginBottom: "16px" }}>
        <Typography variant="body1" className="medium">
          In order to complete this transaction, please{" "}
          <span className="bold">
            check your email for a link from Docusign
          </span>{" "}
          to sign the Security Token Purchase Agreement and Operating Agreement
          for <span className="semi-bold blue-text">{name}</span>.
        </Typography>
      </div>
      <HomebaseButton href="/dashboard">
        Take Me to the Dashboard
      </HomebaseButton>
    </div>
  );
};

export default ConfirmationPage;
