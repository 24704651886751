import { CoinflowPurchase } from "@coinflowlabs/react";
import { useConnection } from "@solana/wallet-adapter-react";
import { useAuth } from "../context/auth";
import { useHomebaseWallet } from "../solana/wallet/Wallet";
import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { Offering, OnCreateTransactionSubscription } from "../API";
import { formatUtils } from "../utils/format";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import HelloSign from "hellosign-embedded";
import { HomebaseButton } from "../common/HomebaseButton";
import * as subs from "../graphql/subscriptions";
import { API, Auth } from "aws-amplify";
import { GraphQLSubscription } from "@aws-amplify/api";

const ENV = process.env.REACT_APP_ENV;
const COINFLOW_ENV =
  process.env.REACT_APP_ENV === "prod" ||
  process.env.REACT_APP_ENV === "staging"
    ? "prod"
    : "sandbox";

export const Checkout = () => {
  const location = useLocation();
  const { offering } = location.state as { offering: Offering };
  const [amount, setAmount] = useState(offering.minInvestment || 20);
  const [purchased, setPurchased] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signed, setSigned] = useState(false);
  const [signatureId, setSignatureId] = useState<string | null | undefined>();
  const { connection } = useConnection();
  const { attributes } = useAuth();
  const { wallet } = useHomebaseWallet();
  const { formatNumber } = formatUtils;
  const signClient = new HelloSign();
  const theme = useTheme();

  useEffect(() => {
    const onTXCreateSub = API.graphql<
      GraphQLSubscription<OnCreateTransactionSubscription>
    >({
      query: subs.onCreateTransaction,
    }).subscribe({
      next({ provider, value }) {
        console.log({ provider, value });
        setSignatureId(value.data?.onCreateTransaction?.signatureId);
      },
      error(err) {
        console.error("Subscription Error", err);
      },
    });

    console.log("in use effect");

    return () => {
      onTXCreateSub.unsubscribe();
    };
  }, []);

  const getSignatureURL = async () => {
    const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
    const result = await API.post("offerings", "/offering/sign_url", {
      headers: { Authorization: jwt },
      body: {
        signatureId,
      },
    });

    console.log(result);
    return result.signUrl;
  };

  if (!wallet) {
    return <div>Wallet not connected</div>;
  }

  const signDocument = async () => {
    const url = await getSignatureURL();

    signClient.open(url, {
      clientId: "3f9c386d953b167c02671cdfbbecb40a",
      skipDomainVerification: !(ENV === "prod"),
    });
  };

  return (
    <Grid container height="calc(100vh - 80px)">
      <Grid
        item
        xs={12}
        sm={3}
        sx={{
          backgroundColor: theme.custom.divBackground,
          borderRadius: "12px",
          marginTop: "20px",
        }}
      >
        <div style={{ padding: "16px", width: "240px", margin: "auto" }}>
          <Box
            sx={{
              borderRadius: "25px",
              border: "5px solid var(--Secondary-Grey, #F2F2F2)",
              backgroundImage: `url(${offering.home?.images[0]})`,
              backgroundSize: "cover",
              boxShadow: "1px 2px 4px 3px rgba(0, 0, 0, 0.25)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "240px",
              marginY: "42px",
            }}
          />
          <Typography variant="h4" className="semi-bold" color="primary">
            {offering.home?.name}
          </Typography>
          <Typography
            variant="body1"
            className="semi-bold"
            marginTop="10px"
            color="primary"
          >
            {offering.home?.address}
          </Typography>
          <Typography variant="body1" className="semi-bold" color="primary">
            {offering.home?.city}, {offering.home?.state}
          </Typography>
          <Typography variant="body1" className="semi-bold" marginTop="5px">
            {offering.home?.numBeds} beds &#8226; {offering.home?.numBaths}{" "}
            baths &#8226; {offering.home?.squareFt} sq ft
          </Typography>
          <Typography variant="body2" className="semi-bold" marginTop="10px">
            Projected IRR{" "}
            <span style={{ float: "right", color: theme.palette.primary.main }}>
              {offering.home?.irr}%
            </span>
          </Typography>
          <Typography variant="body1" marginTop="20px">
            Price per Token{" "}
            <span style={{ float: "right" }} className="semi-bold">
              ${offering.pricePerShare}
            </span>
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              margin: "10px 0px",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2">Quantity</Typography>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <IconButton
                disabled={amount <= (offering.minInvestment || 10)}
                onClick={() => setAmount(isNaN(amount) ? 1 : amount - 1)}
                sx={{ p: 0 }}
                color="primary"
              >
                <RemoveCircleOutlineOutlinedIcon />
              </IconButton>
              <TextField
                type="number"
                size="small"
                variant="standard"
                value={amount}
                sx={{
                  width: { xs: "50px", sm: "50px" },
                }}
                inputProps={{ style: { textAlign: "center" } }}
                onChange={(e) => {
                  let int = parseInt(e.target.value);
                  if (isNaN(int)) {
                    setAmount(offering.minInvestment || 10);
                  } else if (int > 20) {
                    setAmount(20);
                  } else {
                    setAmount(int);
                  }
                }}
              />
              <IconButton
                onClick={() => setAmount(isNaN(amount) ? 1 : amount + 1)}
                disabled={amount >= 20}
                sx={{ p: 0 }}
                color="primary"
              >
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            </div>
          </div>
          <Divider variant="fullWidth" sx={{ borderColor: "black" }} />
          <Typography variant="caption" component="p" className="grey-text">
            Resulting Ownership
            <span style={{ float: "right" }}>Total Cost</span>
          </Typography>
          <Typography variant="body1" className="semi-bold" marginBottom="42px">
            {formatNumber((amount / (offering.totalSupply || 1799)) * 100)}%
            <span style={{ float: "right" }}>
              ${amount * (offering.pricePerShare || 100)}
            </span>
          </Typography>
        </div>
      </Grid>
      <Grid container item xs={12} sm={9} justifyContent="center">
        {!purchased && (
          <CoinflowPurchase
            wallet={wallet}
            merchantId={offering.entity?.toLowerCase() || "homebase"}
            env={COINFLOW_ENV}
            connection={connection}
            onSuccess={() => {
              console.log("Purchase Success");
              setTimeout(() => {
                setPurchased(true);
              }, 2000);
            }}
            blockchain={"solana"}
            webhookInfo={{
              item: offering.home?.name || "RWA Token",
              quantity: amount,
              userId: attributes.id,
              offeringId: offering.id,
            }}
            email={attributes.email}
            amount={amount * (offering.pricePerShare || 1000)}
          />
        )}
        {purchased && !signed && (
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h4" className="semi-bold">
              <span style={{ color: theme.palette.primary.main }}>
                {attributes.firstName}
              </span>{" "}
              - You're almost there!
            </Typography>
            <Typography variant="body1" marginTop="10px">
              Please review and sign the enclosed documents via Dropbox to
              complete your purchase.
            </Typography>
            <Box sx={{ marginTop: "20px" }}>
              <HomebaseButton onClick={signDocument}>
                Sign Documents
              </HomebaseButton>
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
