import jsonp from "jsonp";

const EMAIL_REGEXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const MAILCHIMP_BASE_URL =
  "https://homebasedao.us14.list-manage.com/subscribe/post-json?u=8b4db7caed0a9072676882141&id=a31aadb547&EMAIL=";

export const verifyEmail = (email: string) => {
  return email.match(EMAIL_REGEXP);
};

export const registerMailingList = (
  email: string,
  firstName: string,
  lastName: string,
  callback: any,
  tags?: string
) => {
  jsonp(
    MAILCHIMP_BASE_URL +
      email +
      `&FNAME=${firstName}` +
      `&LNAME=${lastName}` +
      `&tags=7193249,7195249${tags ? "," + tags : ""}`,
    { param: "c" },
    (err, data) => {
      callback(err, data);
    }
  );
};
