import { Metaplex, walletAdapterIdentity } from "@metaplex-foundation/js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { createContext, useContext, useMemo } from "react";

const DEFAULT_CTX: { metaplex: Metaplex | null } = {
  metaplex: null,
};

export const MetaplexContext = createContext(DEFAULT_CTX);

export const useMetaplex = () => {
  return useContext(MetaplexContext);
};

export const MetaplexProvider = ({ children }: { children: any }) => {
  const { connection } = useConnection();
  const wallet = useWallet();

  const metaplex = useMemo(
    () => Metaplex.make(connection).use(walletAdapterIdentity(wallet)),
    [connection, wallet]
  );

  return (
    <MetaplexContext.Provider value={{ metaplex }}>
      {children}
    </MetaplexContext.Provider>
  );
};
