import { styled, TextField } from "@mui/material";

export const RoundedInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px",
    backgroundColor:
      theme.palette.mode === "dark" ? "" : theme.custom.divBackground,
    border: "none",
  },
  "& input:-webkit-autofill": {
    WebkitBoxShadow: `0 0 0 100px ${theme.custom.divBackground} inset`,
    WebkitTextFillColor: theme.palette.text.primary,
  },
  borderRadius: "8px",
}));
