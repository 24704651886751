import twitter from "../../static/twitter.png";
import discord from "../../static/discord.png";
import medium from "../../static/medium.png";
import linkedIn from "../../static/linkedin.svg";
import { IFooterSection } from "./interfaces";

const footerSections: IFooterSection[] = [
  {
    title: "Twitter",
    icon: twitter,
    ref: "https://twitter.com/HomebaseDAO",
  },
  {
    title: "Discord",
    icon: discord,
    ref: "https://discord.gg/qEpCfTyqSY",
  },

  {
    title: "Medium",
    icon: medium,
    ref: "https://homebasedao.medium.com/",
  },
  {
    title: "LinkedIn",
    icon: linkedIn,
    ref: "https://www.linkedin.com/company/homebasedao",
  },
];

export { footerSections };
