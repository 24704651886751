import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { SimpleHomebaseButton } from "../../common/HomebaseButton";
import logo from "../../static/logo.svg";
import solana from "../../static/solana.svg";
import web3auth from "../../static/web3auth.svg";
import { useHomebaseWallet } from "../../solana/wallet/Wallet";
import { useNavigate } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useEffect, useState } from "react";

export const WalletStep = () => {
  const [loading, setLoading] = useState(true);
  const wallet = useHomebaseWallet();
  const navigate = useNavigate();

  useEffect(() => {
    if (!wallet.loading) {
      if (wallet.wallet) {
        navigate("/");
      }

      setLoading(false);
    }
  }, [wallet, navigate]);

  const skip = () => {
    navigate("/");
  };

  return (
    <Container sx={{ width: "100%", maxWidth: "740px !important" }}>
      <Backdrop open={loading} sx={{ color: "#fff", zIndex: 2000 }}>
        <CircularProgress sx={{ color: "#3300cc" }} thickness={4} size={100} />
      </Backdrop>
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <img src={logo} alt="Homebase Logo" width="160px" />
        <Typography variant="h4" align="left">
          Create or Provision a Wallet
        </Typography>
        <Typography variant="subtitle1" align="left">
          This page is designed to help you get started by creating or
          connecting a wallet.
        </Typography>
      </Box>
      <WalletSelect />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="text" onClick={skip}>
          Skip
        </Button>
      </Box>
    </Container>
  );
};

export const WalletSelect = () => {
  const [loading, setLoading] = useState(false);
  const wallet = useHomebaseWallet();
  const { connected } = useWallet();

  const connectWallet = async () => {
    setLoading(true);
    await wallet.connectWallet();
    setLoading(false);
  };

  const createWallet = async () => {
    setLoading(true);
    await wallet.createWallet();
    setLoading(false);
  };

  return (
    <Grid container spacing={3}>
      <Backdrop open={loading} sx={{ color: "#fff", zIndex: 2000 }}>
        <CircularProgress sx={{ color: "#3300cc" }} thickness={4} size={100} />
      </Backdrop>
      <Grid item xs={12} sm={6}>
        <Card className="dash-card">
          <CardContent>
            <Box
              display="flex"
              justifyContent="center"
              marginBottom="16px"
              height="100px"
            >
              <img src={solana} alt="Solana Logo" width="100px" />
            </Box>
            <Typography variant="h5">Connect a Native Wallet</Typography>
            <Typography variant="body2">
              If you already have a native wallet, you can connect it here.
            </Typography>
          </CardContent>
          <CardActions>
            {connected ? (
              <SimpleHomebaseButton
                fullWidth
                variant="contained"
                onClick={connectWallet}
                disableElevation
              >
                Link Wallet
              </SimpleHomebaseButton>
            ) : (
              <WalletMultiButton
                style={{
                  width: "100%",
                  borderRadius: "20px",
                  height: "43.5px",
                  textAlign: "center",
                  fontSize: "1.125rem",
                  justifyContent: "center",
                }}
              />
            )}
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card className="dash-card">
          <CardContent>
            <Box
              display="flex"
              justifyContent="center"
              marginBottom="16px"
              height="100px"
            >
              <img src={web3auth} alt="Card Logo" height="100%" />
            </Box>
            <Typography variant="h5">Provision a New Wallet</Typography>
            <Typography variant="body2">
              If you are new to this, you can create a new wallet here.
            </Typography>
          </CardContent>
          <CardActions>
            <SimpleHomebaseButton
              fullWidth
              variant="contained"
              onClick={createWallet}
              disableElevation
            >
              Create
            </SimpleHomebaseButton>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
