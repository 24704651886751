import { Typography } from "@mui/material";

interface ErrorProps {
  status: number;
  title: string;
  message: string;
  image?: string;
  action?: JSX.Element;
}

export const Error = (props: ErrorProps) => {
  const { status, title, message, image, action } = props;

  return (
    <div
      className="grid-container"
      style={{ textAlign: "center", margin: "200px 0px" }}
    >
      {image && (
        <img
          src={image}
          alt="Error graphic"
          height={200}
          style={{ padding: "32px 0px" }}
        />
      )}
      <Typography variant="h5">
        <b>
          {status}: <span className="blue-text">{title}</span>
        </b>
      </Typography>
      <Typography variant="body1">{message}</Typography>
      {action}
    </div>
  );
};
