import {
  Alert,
  AlertColor,
  Box,
  Collapse,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HomebaseButton } from "../../common/HomebaseButton";
import { RoundedInput } from "../../common/Inputs";
import logo_stacked from "../../static/logo_stacked.svg";
import { useAuth } from "../../context/auth";

interface AlertObject {
  severity: AlertColor;
  message: string;
}

export const VerifyPage = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<AlertObject>({
    severity: "warning",
    message: "",
  });
  const { confirmSignUp } = useAuth();
  const navigate = useNavigate();
  const email = new URLSearchParams(window.location.search).get(
    "email"
  ) as string;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const code = data.get("code")?.toString() as string;

    verifyUser(email, code);
  };

  const verifyUser = async (email: string, code: string) => {
    setLoading(true);

    try {
      await confirmSignUp(email, code);
      analytics.track("confirmed_signup");
    } catch (error) {
      setAlert({ severity: "error", message: (error as any).message });
    }

    setLoading(false);
  };

  const resendConfirmationCode = async () => {
    setLoading(true);

    try {
      await Auth.resendSignUp(email);
      setAlert({ severity: "success", message: "Code resent!" });
    } catch (error) {
      setAlert({ severity: "error", message: (error as any).message });
    }

    setLoading(false);
  };

  return (
    <Container maxWidth="xl" sx={{ height: "100vh" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100%"
        justifyContent="center"
        maxWidth={420}
        margin="auto"
      >
        <img
          src={logo_stacked}
          alt="Homebase Logo"
          style={{ height: "128px", padding: "32px" }}
          className="clickable"
          onClick={() => navigate("/")}
        />
        <Stepper activeStep={2} sx={{ width: "240px" }}>
          <Step>
            <StepLabel />
          </Step>
          <Step>
            <StepLabel />
          </Step>
          <Step>
            <StepLabel />
          </Step>
        </Stepper>
        <Box component="form" onSubmit={handleSubmit}>
          <Typography variant="h6" className="semi-bold" sx={{ mt: 2 }}>
            You are one step away from completing your account.
          </Typography>
          <Typography>
            A verification email has been sent to your inbox. Enter the code
            here
          </Typography>
          <RoundedInput
            margin="normal"
            fullWidth
            label="Code"
            name="code"
            className="input-box"
            type="text"
            size="small"
          />
          <div onClick={resendConfirmationCode}>
            <Typography
              variant="body1"
              className="blue-text clickable"
              sx={{ marginBottom: "6px" }}
            >
              Resend Code
            </Typography>
          </div>
          <Collapse in={alert.message.length !== 0}>
            <Alert severity={alert.severity}>{alert.message}</Alert>
          </Collapse>
          <div style={{ margin: "8px 0px" }}>
            <HomebaseButton loading={loading} type="submit">
              Verify Email
            </HomebaseButton>
          </div>
        </Box>
      </Box>
    </Container>
  );
};
