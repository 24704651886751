/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthStatus, useAuth } from "../../context/auth";
import EmbeddedWallet, { IEmbeddedWallet } from "./EmbeddedWallet";
import { useWallet, WalletContextState } from "@solana/wallet-adapter-react";
import { API, Auth } from "aws-amplify";
import bs58 from "bs58";
import { PublicKey } from "@metaplex-foundation/js";

interface IWalletContext {
  connectWallet: () => Promise<boolean>;
  createWallet: () => Promise<boolean>;
  wallet: WalletContextState | IEmbeddedWallet | null;
  loading: boolean;
}

const WalletContext = createContext<IWalletContext>({
  connectWallet: () => {
    return new Promise(() => {});
  },
  createWallet: () => {
    return new Promise(() => {});
  },
  wallet: null,
  loading: true,
});

export const Wallet = ({ children }: { children: ReactNode }) => {
  const { attributes, authStatus, updateUserAttributes } = useAuth();
  const solanaWallet = useWallet();
  const [embeddedWallet, setEmbeddedWallet] = useState<IEmbeddedWallet | null>(
    null
  );
  const [nativeWallet, setNativeWallet] = useState<WalletContextState | null>(
    null
  );
  const [loading, setLoading] = useState(true);

  let wallet = null;

  useEffect(() => {
    const init = async () => {
      console.log("test");
      if (attributes.walletType === 1) {
        console.log("in");
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        const embed = await EmbeddedWallet.createWallet(user);
        console.log(embed);
        setEmbeddedWallet(embed);
      } else if (attributes.walletType === 0 || attributes.wallet) {
        solanaWallet.publicKey = new PublicKey(attributes.wallet || "");
        setNativeWallet(solanaWallet);
      }

      setLoading(false);
    };

    if (authStatus === AuthStatus.SignedIn) {
      init();
    }
  }, [authStatus]);

  const connectWallet = async () => {
    if (solanaWallet.signMessage) {
      const msg = await solanaWallet.signMessage(
        new TextEncoder().encode(
          "Wallet Connect: d7PQBAIGKPpgZjdeNOdYlPMTCOvEYVpp"
        )
      );
      const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
      await API.post("onboarding", "/onboarding/wallet", {
        headers: { Authorization: `Bearer ${jwt}` },
        body: {
          walletType: 0,
          walletAddress: solanaWallet.publicKey,
          signature: bs58.encode(msg),
        },
      });
      console.log("here");
      const newAttributes = { ...attributes, walletType: 0 };
      updateUserAttributes(newAttributes);
      setNativeWallet(solanaWallet);
      return true;
    }

    return false;
  };

  const createWallet = async () => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const embed = await EmbeddedWallet.createWallet(user);
    if (embed.connected) {
      const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
      await API.post("onboarding", "/onboarding/wallet", {
        headers: { Authorization: `Bearer ${jwt}` },
        body: {
          walletType: 1,
          walletAddress: embed.publicKey,
        },
      });
      const newAttributes = { ...attributes, walletType: 1 };
      updateUserAttributes(newAttributes);
      setEmbeddedWallet(embed);
      return true;
    }

    return false;
  };

  if (attributes.walletType === 1) {
    wallet = embeddedWallet;
  } else if (attributes.walletType === 0 || attributes.wallet) {
    wallet = nativeWallet;
  }

  return (
    <WalletContext.Provider
      value={{ connectWallet, createWallet, wallet, loading }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useHomebaseWallet = () => useContext(WalletContext);
