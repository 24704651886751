import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import {
  Box,
  Breakpoint,
  CircularProgress,
  Container,
  CssBaseline,
} from "@mui/material";
import { Header } from "./common/header/Header";
import { Footer } from "./common/footer/Footer";
import { AuthProvider, ProtectedPage, SignedOutPage } from "./context/auth";
import { lazy, Suspense, useEffect, useMemo } from "react";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { LoginPage } from "./login/Login";
import { RegisterPage } from "./register/Register";
import { VerifyPage } from "./register/verify/Verify";
import { RecoverPage } from "./recover/Recover";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  ExodusWalletAdapter,
  BraveWalletAdapter,
  BackpackWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  CloverWalletAdapter,
  Coin98WalletAdapter,
  LedgerWalletAdapter,
  SolongWalletAdapter,
  MathWalletAdapter,
  GlowWalletAdapter,
  SpotWalletAdapter,
  BitKeepWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { MetaplexProvider } from "./context/metaplex";
import ConfirmationPage from "./confirmation/Confirmation";
import { Error } from "./common/Error";
import not_found from "./static/not_found.svg";
import { WalletStep } from "./register/steps/Wallet";
import "./property/Projects.css";
import { Wallet } from "./solana/wallet/Wallet";
import { Checkout } from "./checkout/Checkout";
import { ThemeNameProvider } from "./common/ThemeSelector";

require("@solana/wallet-adapter-react-ui/styles.css");

Amplify.configure(awsExports);

const MarketplacePage = lazy(() => import("./marketplace/Marketplace"));
const PropertyPage = lazy(() => import("./property/Property"));
const DashboardPage = lazy(() => import("./dashboard/Dashboard"));

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      divBackground: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      divBackground?: string;
    };
  }
}

declare global {
  interface Window {
    Intercom: any;
    Calendly: any;
    solana: any;
  }
}

function App() {
  let endpoint =
    "https://serene-soft-shard.solana-devnet.quiknode.pro/9ce758426ee69215dcf61cdb76071cc35d2be7b8/";

  if (
    process.env.REACT_APP_ENV === "prod" ||
    process.env.REACT_APP_ENV === "staging"
  ) {
    endpoint =
      "https://withered-fabled-yard.solana-mainnet.quiknode.pro/6a20ebb37d7af50aa93827c57fce68c793f054c2/";
  }

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new ExodusWalletAdapter(),
      new BraveWalletAdapter(),
      new BackpackWalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(),
      new CloverWalletAdapter(),
      new Coin98WalletAdapter(),
      new LedgerWalletAdapter(),
      new SolongWalletAdapter(),
      new MathWalletAdapter(),
      new GlowWalletAdapter(),
      new SpotWalletAdapter(),
      new BitKeepWalletAdapter(),
    ],
    []
  );

  useEffect(() => {
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "w2tcgqa7",
    });
  }, []);

  return (
    <Router>
      <ThemeNameProvider>
        <CssBaseline />
        <AuthProvider>
          <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets}>
              <Wallet>
                <WalletModalProvider>
                  <MetaplexProvider>
                    <div style={{ position: "relative" }}>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <PageWrap
                              maxWidth="xl"
                              page={
                                <ProtectedPage>
                                  <MarketplacePage />
                                </ProtectedPage>
                              }
                            />
                          }
                        />
                        <Route
                          path="checkout"
                          element={
                            <PageWrap
                              maxWidth="xl"
                              page={
                                <ProtectedPage>
                                  <Checkout />
                                </ProtectedPage>
                              }
                            />
                          }
                        />
                        <Route
                          path="property"
                          element={
                            <Suspense
                              fallback={
                                <Box display="flex" height="600px">
                                  <CircularProgress sx={{ margin: "auto" }} />
                                </Box>
                              }
                            >
                              <PageWrap page={<PropertyPage />} />
                            </Suspense>
                          }
                        />
                        <Route
                          path="dashboard/*"
                          element={
                            <ProtectedPage>
                              <Suspense
                                fallback={
                                  <Box display="flex" height="600px">
                                    <CircularProgress sx={{ margin: "auto" }} />
                                  </Box>
                                }
                              >
                                <DashboardPage />
                              </Suspense>
                            </ProtectedPage>
                          }
                        />
                        <Route
                          path="confirmation"
                          element={
                            <ProtectedPage>
                              <PageWrap page={<ConfirmationPage />} />
                            </ProtectedPage>
                          }
                        />
                        <Route
                          path="login"
                          element={
                            <SignedOutPage>
                              <LoginPage />
                            </SignedOutPage>
                          }
                        />
                        <Route
                          path="register"
                          element={
                            <SignedOutPage>
                              <RegisterPage />
                            </SignedOutPage>
                          }
                        />
                        <Route
                          path="register/verify"
                          element={
                            <SignedOutPage>
                              <VerifyPage />
                            </SignedOutPage>
                          }
                        />
                        <Route path="wallet" element={<WalletStep />} />
                        <Route
                          path="recover"
                          element={
                            <SignedOutPage>
                              <RecoverPage />
                            </SignedOutPage>
                          }
                        />
                        <Route
                          path="*"
                          element={
                            <ProtectedPage>
                              <PageWrap
                                page={
                                  <Error
                                    status={404}
                                    title="Page Not Found"
                                    message="Looks like you've wandered outside the Homebase."
                                    image={not_found}
                                  />
                                }
                              />
                            </ProtectedPage>
                          }
                        />
                      </Routes>
                    </div>
                  </MetaplexProvider>
                </WalletModalProvider>
              </Wallet>
            </WalletProvider>
          </ConnectionProvider>
        </AuthProvider>
      </ThemeNameProvider>
    </Router>
  );
}

interface PageProps {
  page: JSX.Element;
  maxWidth?: false | Breakpoint;
}

const PageWrap = (props: PageProps) => {
  const { page, maxWidth } = props;
  const width = maxWidth || "lg";

  return (
    <>
      <Header maxWidth={width} />
      <Container maxWidth={width} className="page-wrap">
        <Suspense
          fallback={
            <Box display="flex" height="600px">
              <CircularProgress sx={{ margin: "auto" }} />
            </Box>
          }
        >
          {page}
        </Suspense>
      </Container>
      <Footer maxWidth={width} />
    </>
  );
};

export default App;
