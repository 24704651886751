import {
  Alert,
  Collapse,
  Container,
  CssBaseline,
  Divider,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { HomebaseButton } from "../common/HomebaseButton";
import { RoundedInput } from "../common/Inputs";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import login_logo from "../static/login_logo.svg";
import sponsor_logo from "../static/empiredao_homebase.svg";

const EmpireDaoTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#9747FF",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "#2B2B2D",
    },
  },
  custom: {
    divBackground: "#121212",
  },
});

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [parameters] = useSearchParams();
  const { login } = useAuth();
  const navigate = useNavigate();
  const referrer = parameters.get("referrer");
  const globalTheme = useTheme();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string;
    const password = data.get("password") as string;

    signIn(email, password);
  };

  const signIn = async (email: string, password: string) => {
    setLoading(true);
    try {
      await login(email, password);
    } catch (error) {
      var err = error as any;
      if (err.name === "UserNotConfirmedException") {
        navigate(`/register/verify?email=${email}`);
      }
      setAlert(err.message);
    }
    setLoading(false);
  };

  const theme = referrer ? EmpireDaoTheme : globalTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl">
        <Grid container sx={{ height: "100vh" }}>
          {theme.palette.mode === "light" && (
            <Grid item xs={12} sm={5} position="relative">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  backgroundColor: "#0f6eff",
                  margin: "0px -16px",
                }}
              >
                <img
                  src={login_logo}
                  alt="Login graphic"
                  style={{ width: "80%", margin: "auto" }}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={12} sm={theme.palette.mode === "light" ? 7 : 12}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
              justifyContent="center"
              maxWidth={420}
              margin="auto"
            >
              {referrer?.toLowerCase() === "empiredao" && (
                <img src={sponsor_logo} alt="Login graphic" />
              )}
              <Typography variant="h5" className="semi-bold" sx={{ mt: 2 }}>
                Log In to{" "}
                {referrer?.toLowerCase() === "empiredao"
                  ? "EmpireDao"
                  : "Homebase"}
              </Typography>
              <Box component="form" onSubmit={handleSubmit}>
                <RoundedInput
                  margin="normal"
                  fullWidth
                  label="Email"
                  name="email"
                  autoComplete="email"
                  type="email"
                  size="small"
                />
                <RoundedInput
                  margin="normal"
                  fullWidth
                  label="Password"
                  name="password"
                  autoComplete="password"
                  type="password"
                  size="small"
                />
                <Collapse in={alert.length !== 0}>
                  <Alert severity="error">{alert}</Alert>
                </Collapse>
                <div style={{ margin: "8px 0px" }}>
                  <HomebaseButton loading={loading} type="submit">
                    Log In
                  </HomebaseButton>
                </div>
                <Typography sx={{ float: "right" }}>
                  <a
                    href="recover"
                    className="clickable"
                    style={{ color: theme.palette.primary.main }}
                  >
                    Forgot Password?
                  </a>
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Divider sx={{ mt: 2, mb: 2 }}>Or</Divider>
              </div>
              <Typography>
                Don't have an account?{" "}
                <a
                  href={`register${referrer ? "?referrer=" + referrer : ""}`}
                  className="clickable"
                  style={{ color: theme.palette.primary.main }}
                >
                  Sign up now
                </a>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};
