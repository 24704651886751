import React, { createContext, useContext, useState, ReactNode } from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";

interface ThemeContextType {
  setThemeName: (themeName: string) => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useThemeName = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useThemeName must be used within a ThemeNameProvider");
  }
  return context;
};

interface ThemeNameProviderProps {
  children: ReactNode; // Explicitly define the children prop type
}

export const ThemeNameProvider: React.FC<ThemeNameProviderProps> = ({
  children,
}) => {
  const [themeName, setThemeName] = useState("default");

  const theme =
    themeName === "empiredao"
      ? createTheme({
          palette: {
            mode: "dark",
            primary: {
              main: "#9747FF",
            },
            secondary: {
              main: "#FFFFFF",
            },
            background: {
              default: "#2B2B2D",
            },
          },
          custom: {
            divBackground: "#121212",
          },
        })
      : createTheme({
          typography: {
            fontFamily: "Poppins",
          },
          palette: {
            mode: "light",
            primary: {
              main: "#0f6eff",
            },
            secondary: {
              main: "#3300cc",
            },
          },
          custom: {
            divBackground: "#f2f2f2",
          },
        });

  return (
    <ThemeContext.Provider value={{ setThemeName }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
