import {
  Alert,
  Collapse,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HomebaseButton } from "../common/HomebaseButton";
import { RoundedInput } from "../common/Inputs";
import logo_stacked from "../static/logo_stacked.svg";

export const RecoverPage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (currentStep === 0) {
      const userEmail = data.get("email") as string;
      sendRecoveryEmail(userEmail);
      setEmail(userEmail);
    }

    if (currentStep === 1) {
      const code = data.get("code") as string;
      const password = data.get("password") as string;
      recoverPassword(code, password);
    }
  };

  const sendRecoveryEmail = async (email: string) => {
    setLoading(true);
    try {
      await Auth.forgotPassword(email);
      setCurrentStep(1);
    } catch (error) {
      setAlert((error as any).message);
    }
    setLoading(false);
  };

  const recoverPassword = async (code: string, password: string) => {
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      navigate("/login");
    } catch (error) {
      setAlert((error as any).message);
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="xl" sx={{ height: "100vh" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100%"
        justifyContent="center"
        maxWidth={420}
        margin="auto"
      >
        <img
          src={logo_stacked}
          alt="Homebase Logo"
          style={{ height: "128px", padding: "32px" }}
          className="clickable"
          onClick={() => navigate("/")}
        />
        <Stepper activeStep={currentStep} sx={{ width: "240px" }}>
          <Step key={0}>
            <StepLabel />
          </Step>
          <Step key={1}>
            <StepLabel />
          </Step>
        </Stepper>
        {currentStep ? (
          <Box component="form" onSubmit={handleSubmit}>
            <Typography variant="h5" className="semi-bold" sx={{ mt: 2 }}>
              Recover Password
            </Typography>
            <Typography>
              A recovery code has been sent to your inbox.
            </Typography>
            <RoundedInput
              margin="normal"
              fullWidth
              label="Recovery Code"
              name="code"
              className="input-box"
              type="text"
              size="small"
              required
            />
            <RoundedInput
              margin="normal"
              fullWidth
              label="New Password"
              name="password"
              className="input-box"
              type="password"
              size="small"
              required
            />
            <div onClick={() => sendRecoveryEmail(email)}>
              <Typography
                variant="body1"
                className="blue-text clickable"
                sx={{ marginBottom: "6px" }}
              >
                Resend Code
              </Typography>
            </div>
            <Collapse in={alert.length !== 0}>
              <Alert severity="error">{alert}</Alert>
            </Collapse>
            <div style={{ margin: "8px 0px" }}>
              <HomebaseButton loading={loading} type="submit">
                Continue to Last Step
              </HomebaseButton>
            </div>
          </Box>
        ) : (
          <>
            <Typography variant="h5" className="semi-bold" sx={{ mt: 2 }}>
              Recover Password
            </Typography>
            <div>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ minWidth: "300px" }}
              >
                <RoundedInput
                  margin="normal"
                  fullWidth
                  label="Email"
                  name="email"
                  autoComplete="email"
                  className="input-box"
                  type="email"
                  size="small"
                  required
                />
                <Collapse in={alert.length !== 0}>
                  <Alert severity="error">{alert}</Alert>
                </Collapse>
                <div style={{ margin: "8px 0px" }}>
                  <HomebaseButton loading={loading} type="submit">
                    Send Recovery Code
                  </HomebaseButton>
                </div>
              </Box>
            </div>
          </>
        )}
      </Box>
    </Container>
  );
};
