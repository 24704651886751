import { Box, Button, CircularProgress, styled, useTheme } from "@mui/material";
import { green } from "@mui/material/colors";

interface ButtonProps {
  children: any;
  type?: "submit" | "button" | "reset";
  loading?: any;
  onClick?: any;
  disabled?: boolean;
  href?: string;
  success?: boolean;
  logo?: string;
  sx?: any;
}

export const HomebaseButton = ({
  children,
  type,
  loading,
  onClick,
  disabled,
  href,
  success,
  logo,
  sx,
}: ButtonProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative" }}>
      <Button
        fullWidth
        variant="contained"
        style={
          loading || disabled
            ? { borderRadius: "20px", textTransform: "none" }
            : {
                borderRadius: "20px",
                backgroundColor: success
                  ? green[500]
                  : theme.palette.primary.main,
                textTransform: "none",
              }
        }
        onClick={onClick}
        disabled={loading || disabled}
        type={type}
        href={href}
        sx={sx}
        startIcon={
          logo ? <img src={logo} height="24px" alt="Icon" /> : undefined
        }
      >
        {success ? "Thank you!" : children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
};

export const SimpleHomebaseButton = styled(Button)({
  borderRadius: "20px",
  backgroundColor: "#0f6eff",
  textTransform: "none",
  fontWeight: 600,
  fontSize: "1.125rem",
  color: "white",
});
