/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateOffering = /* GraphQL */ `
  subscription OnCreateOffering($filter: ModelSubscriptionOfferingFilterInput) {
    onCreateOffering(filter: $filter) {
      id
      cmAddress
      home {
        id
        name
        status
        address
        city
        state
        zipCode
        numBeds
        numBaths
        squareFt
        totalSupply
        remainingSupply
        description
        images
        irr
        features {
          nextToken
        }
        coordinate {
          id
          longitude
          latitude
          createdAt
          updatedAt
        }
        market {
          id
          name
          medPrice
          avgRent
          popGrowth
          description
          image
          createdAt
          updatedAt
        }
        financialProjection {
          id
          totalValue
          assetValue
          closingFee
          reserve
          totalReturn
          projAppr
          avgROI
          capRate
          mGrossRent
          vacancy
          utilities
          mngmtFee
          franchiseFee
          eiRatio
          totalCost
          cashFlow
          propertyTax
          propertyInsurance
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyingProcess {
          nextToken
        }
        createdAt
        updatedAt
        homeCoordinateId
        homeMarketId
        homeFinancialProjectionId
      }
      status
      totalSupply
      remainingSupply
      pricePerShare
      minInvestment
      maxInvestment
      projectedValue
      nftArt
      date
      groups {
        items {
          id
          date
          members
          createdAt
          updatedAt
          offeringGroupsId
        }
        nextToken
      }
      comments {
        items {
          id
          offeringId
          firstName
          text
          createdAt
          updatedAt
          offeringCommentsId
          owner
        }
        nextToken
      }
      likesDislikes {
        items {
          id
          type
          createdAt
          updatedAt
          offeringLikesDislikesId
          owner
        }
        nextToken
      }
      views
      createdAt
      updatedAt
      offeringHomeId
    }
  }
`;
export const onUpdateOffering = /* GraphQL */ `
  subscription OnUpdateOffering($filter: ModelSubscriptionOfferingFilterInput) {
    onUpdateOffering(filter: $filter) {
      id
      cmAddress
      home {
        id
        name
        status
        address
        city
        state
        zipCode
        numBeds
        numBaths
        squareFt
        totalSupply
        remainingSupply
        description
        images
        irr
        features {
          nextToken
        }
        coordinate {
          id
          longitude
          latitude
          createdAt
          updatedAt
        }
        market {
          id
          name
          medPrice
          avgRent
          popGrowth
          description
          image
          createdAt
          updatedAt
        }
        financialProjection {
          id
          totalValue
          assetValue
          closingFee
          reserve
          totalReturn
          projAppr
          avgROI
          capRate
          mGrossRent
          vacancy
          utilities
          mngmtFee
          franchiseFee
          eiRatio
          totalCost
          cashFlow
          propertyTax
          propertyInsurance
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyingProcess {
          nextToken
        }
        createdAt
        updatedAt
        homeCoordinateId
        homeMarketId
        homeFinancialProjectionId
      }
      status
      totalSupply
      remainingSupply
      pricePerShare
      minInvestment
      maxInvestment
      projectedValue
      nftArt
      date
      groups {
        items {
          id
          date
          members
          createdAt
          updatedAt
          offeringGroupsId
        }
        nextToken
      }
      comments {
        items {
          id
          offeringId
          firstName
          text
          createdAt
          updatedAt
          offeringCommentsId
          owner
        }
        nextToken
      }
      likesDislikes {
        items {
          id
          type
          createdAt
          updatedAt
          offeringLikesDislikesId
          owner
        }
        nextToken
      }
      views
      createdAt
      updatedAt
      offeringHomeId
    }
  }
`;
export const onDeleteOffering = /* GraphQL */ `
  subscription OnDeleteOffering($filter: ModelSubscriptionOfferingFilterInput) {
    onDeleteOffering(filter: $filter) {
      id
      cmAddress
      home {
        id
        name
        status
        address
        city
        state
        zipCode
        numBeds
        numBaths
        squareFt
        totalSupply
        remainingSupply
        description
        images
        irr
        features {
          nextToken
        }
        coordinate {
          id
          longitude
          latitude
          createdAt
          updatedAt
        }
        market {
          id
          name
          medPrice
          avgRent
          popGrowth
          description
          image
          createdAt
          updatedAt
        }
        financialProjection {
          id
          totalValue
          assetValue
          closingFee
          reserve
          totalReturn
          projAppr
          avgROI
          capRate
          mGrossRent
          vacancy
          utilities
          mngmtFee
          franchiseFee
          eiRatio
          totalCost
          cashFlow
          propertyTax
          propertyInsurance
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        buyingProcess {
          nextToken
        }
        createdAt
        updatedAt
        homeCoordinateId
        homeMarketId
        homeFinancialProjectionId
      }
      status
      totalSupply
      remainingSupply
      pricePerShare
      minInvestment
      maxInvestment
      projectedValue
      nftArt
      date
      groups {
        items {
          id
          date
          members
          createdAt
          updatedAt
          offeringGroupsId
        }
        nextToken
      }
      comments {
        items {
          id
          offeringId
          firstName
          text
          createdAt
          updatedAt
          offeringCommentsId
          owner
        }
        nextToken
      }
      likesDislikes {
        items {
          id
          type
          createdAt
          updatedAt
          offeringLikesDislikesId
          owner
        }
        nextToken
      }
      views
      createdAt
      updatedAt
      offeringHomeId
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
    onCreateComment(filter: $filter) {
      id
      offeringId
      firstName
      text
      createdAt
      updatedAt
      offeringCommentsId
      owner
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
    onUpdateComment(filter: $filter) {
      id
      offeringId
      firstName
      text
      createdAt
      updatedAt
      offeringCommentsId
      owner
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
    onDeleteComment(filter: $filter) {
      id
      offeringId
      firstName
      text
      createdAt
      updatedAt
      offeringCommentsId
      owner
    }
  }
`;
export const onCreateLikeDislike = /* GraphQL */ `
  subscription OnCreateLikeDislike(
    $filter: ModelSubscriptionLikeDislikeFilterInput
  ) {
    onCreateLikeDislike(filter: $filter) {
      id
      type
      createdAt
      updatedAt
      offeringLikesDislikesId
      owner
    }
  }
`;
export const onUpdateLikeDislike = /* GraphQL */ `
  subscription OnUpdateLikeDislike(
    $filter: ModelSubscriptionLikeDislikeFilterInput
  ) {
    onUpdateLikeDislike(filter: $filter) {
      id
      type
      createdAt
      updatedAt
      offeringLikesDislikesId
      owner
    }
  }
`;
export const onDeleteLikeDislike = /* GraphQL */ `
  subscription OnDeleteLikeDislike(
    $filter: ModelSubscriptionLikeDislikeFilterInput
  ) {
    onDeleteLikeDislike(filter: $filter) {
      id
      type
      createdAt
      updatedAt
      offeringLikesDislikesId
      owner
    }
  }
`;
export const onCreateHome = /* GraphQL */ `
  subscription OnCreateHome($filter: ModelSubscriptionHomeFilterInput) {
    onCreateHome(filter: $filter) {
      id
      name
      status
      address
      city
      state
      zipCode
      numBeds
      numBaths
      squareFt
      totalSupply
      remainingSupply
      description
      images
      irr
      features {
        items {
          id
          name
          value
          createdAt
          updatedAt
          homeFeaturesId
        }
        nextToken
      }
      coordinate {
        id
        longitude
        latitude
        createdAt
        updatedAt
      }
      market {
        id
        name
        medPrice
        avgRent
        popGrowth
        description
        image
        createdAt
        updatedAt
      }
      financialProjection {
        id
        totalValue
        assetValue
        closingFee
        reserve
        totalReturn
        projAppr
        avgROI
        capRate
        mGrossRent
        vacancy
        utilities
        mngmtFee
        franchiseFee
        eiRatio
        totalCost
        cashFlow
        propertyTax
        propertyInsurance
        createdAt
        updatedAt
      }
      documents {
        items {
          id
          name
          value
          createdAt
          updatedAt
          homeDocumentsId
        }
        nextToken
      }
      buyingProcess {
        items {
          id
          date
          name
          description
          createdAt
          updatedAt
          homeBuyingProcessId
        }
        nextToken
      }
      createdAt
      updatedAt
      homeCoordinateId
      homeMarketId
      homeFinancialProjectionId
    }
  }
`;
export const onUpdateHome = /* GraphQL */ `
  subscription OnUpdateHome($filter: ModelSubscriptionHomeFilterInput) {
    onUpdateHome(filter: $filter) {
      id
      name
      status
      address
      city
      state
      zipCode
      numBeds
      numBaths
      squareFt
      totalSupply
      remainingSupply
      description
      images
      irr
      features {
        items {
          id
          name
          value
          createdAt
          updatedAt
          homeFeaturesId
        }
        nextToken
      }
      coordinate {
        id
        longitude
        latitude
        createdAt
        updatedAt
      }
      market {
        id
        name
        medPrice
        avgRent
        popGrowth
        description
        image
        createdAt
        updatedAt
      }
      financialProjection {
        id
        totalValue
        assetValue
        closingFee
        reserve
        totalReturn
        projAppr
        avgROI
        capRate
        mGrossRent
        vacancy
        utilities
        mngmtFee
        franchiseFee
        eiRatio
        totalCost
        cashFlow
        propertyTax
        propertyInsurance
        createdAt
        updatedAt
      }
      documents {
        items {
          id
          name
          value
          createdAt
          updatedAt
          homeDocumentsId
        }
        nextToken
      }
      buyingProcess {
        items {
          id
          date
          name
          description
          createdAt
          updatedAt
          homeBuyingProcessId
        }
        nextToken
      }
      createdAt
      updatedAt
      homeCoordinateId
      homeMarketId
      homeFinancialProjectionId
    }
  }
`;
export const onDeleteHome = /* GraphQL */ `
  subscription OnDeleteHome($filter: ModelSubscriptionHomeFilterInput) {
    onDeleteHome(filter: $filter) {
      id
      name
      status
      address
      city
      state
      zipCode
      numBeds
      numBaths
      squareFt
      totalSupply
      remainingSupply
      description
      images
      irr
      features {
        items {
          id
          name
          value
          createdAt
          updatedAt
          homeFeaturesId
        }
        nextToken
      }
      coordinate {
        id
        longitude
        latitude
        createdAt
        updatedAt
      }
      market {
        id
        name
        medPrice
        avgRent
        popGrowth
        description
        image
        createdAt
        updatedAt
      }
      financialProjection {
        id
        totalValue
        assetValue
        closingFee
        reserve
        totalReturn
        projAppr
        avgROI
        capRate
        mGrossRent
        vacancy
        utilities
        mngmtFee
        franchiseFee
        eiRatio
        totalCost
        cashFlow
        propertyTax
        propertyInsurance
        createdAt
        updatedAt
      }
      documents {
        items {
          id
          name
          value
          createdAt
          updatedAt
          homeDocumentsId
        }
        nextToken
      }
      buyingProcess {
        items {
          id
          date
          name
          description
          createdAt
          updatedAt
          homeBuyingProcessId
        }
        nextToken
      }
      createdAt
      updatedAt
      homeCoordinateId
      homeMarketId
      homeFinancialProjectionId
    }
  }
`;
export const onCreateFeature = /* GraphQL */ `
  subscription OnCreateFeature($filter: ModelSubscriptionFeatureFilterInput) {
    onCreateFeature(filter: $filter) {
      id
      name
      value
      createdAt
      updatedAt
      homeFeaturesId
    }
  }
`;
export const onUpdateFeature = /* GraphQL */ `
  subscription OnUpdateFeature($filter: ModelSubscriptionFeatureFilterInput) {
    onUpdateFeature(filter: $filter) {
      id
      name
      value
      createdAt
      updatedAt
      homeFeaturesId
    }
  }
`;
export const onDeleteFeature = /* GraphQL */ `
  subscription OnDeleteFeature($filter: ModelSubscriptionFeatureFilterInput) {
    onDeleteFeature(filter: $filter) {
      id
      name
      value
      createdAt
      updatedAt
      homeFeaturesId
    }
  }
`;
export const onCreateCoordinate = /* GraphQL */ `
  subscription OnCreateCoordinate(
    $filter: ModelSubscriptionCoordinateFilterInput
  ) {
    onCreateCoordinate(filter: $filter) {
      id
      longitude
      latitude
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCoordinate = /* GraphQL */ `
  subscription OnUpdateCoordinate(
    $filter: ModelSubscriptionCoordinateFilterInput
  ) {
    onUpdateCoordinate(filter: $filter) {
      id
      longitude
      latitude
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCoordinate = /* GraphQL */ `
  subscription OnDeleteCoordinate(
    $filter: ModelSubscriptionCoordinateFilterInput
  ) {
    onDeleteCoordinate(filter: $filter) {
      id
      longitude
      latitude
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMarket = /* GraphQL */ `
  subscription OnCreateMarket($filter: ModelSubscriptionMarketFilterInput) {
    onCreateMarket(filter: $filter) {
      id
      name
      medPrice
      avgRent
      popGrowth
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMarket = /* GraphQL */ `
  subscription OnUpdateMarket($filter: ModelSubscriptionMarketFilterInput) {
    onUpdateMarket(filter: $filter) {
      id
      name
      medPrice
      avgRent
      popGrowth
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMarket = /* GraphQL */ `
  subscription OnDeleteMarket($filter: ModelSubscriptionMarketFilterInput) {
    onDeleteMarket(filter: $filter) {
      id
      name
      medPrice
      avgRent
      popGrowth
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFinancialProjection = /* GraphQL */ `
  subscription OnCreateFinancialProjection(
    $filter: ModelSubscriptionFinancialProjectionFilterInput
  ) {
    onCreateFinancialProjection(filter: $filter) {
      id
      totalValue
      assetValue
      closingFee
      reserve
      totalReturn
      projAppr
      avgROI
      capRate
      mGrossRent
      vacancy
      utilities
      mngmtFee
      franchiseFee
      eiRatio
      totalCost
      cashFlow
      propertyTax
      propertyInsurance
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFinancialProjection = /* GraphQL */ `
  subscription OnUpdateFinancialProjection(
    $filter: ModelSubscriptionFinancialProjectionFilterInput
  ) {
    onUpdateFinancialProjection(filter: $filter) {
      id
      totalValue
      assetValue
      closingFee
      reserve
      totalReturn
      projAppr
      avgROI
      capRate
      mGrossRent
      vacancy
      utilities
      mngmtFee
      franchiseFee
      eiRatio
      totalCost
      cashFlow
      propertyTax
      propertyInsurance
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFinancialProjection = /* GraphQL */ `
  subscription OnDeleteFinancialProjection(
    $filter: ModelSubscriptionFinancialProjectionFilterInput
  ) {
    onDeleteFinancialProjection(filter: $filter) {
      id
      totalValue
      assetValue
      closingFee
      reserve
      totalReturn
      projAppr
      avgROI
      capRate
      mGrossRent
      vacancy
      utilities
      mngmtFee
      franchiseFee
      eiRatio
      totalCost
      cashFlow
      propertyTax
      propertyInsurance
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onCreateDocument(filter: $filter) {
      id
      name
      value
      createdAt
      updatedAt
      homeDocumentsId
    }
  }
`;
export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onUpdateDocument(filter: $filter) {
      id
      name
      value
      createdAt
      updatedAt
      homeDocumentsId
    }
  }
`;
export const onDeleteDocument = /* GraphQL */ `
  subscription OnDeleteDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onDeleteDocument(filter: $filter) {
      id
      name
      value
      createdAt
      updatedAt
      homeDocumentsId
    }
  }
`;
export const onCreateBuyingStep = /* GraphQL */ `
  subscription OnCreateBuyingStep(
    $filter: ModelSubscriptionBuyingStepFilterInput
  ) {
    onCreateBuyingStep(filter: $filter) {
      id
      date
      name
      description
      createdAt
      updatedAt
      homeBuyingProcessId
    }
  }
`;
export const onUpdateBuyingStep = /* GraphQL */ `
  subscription OnUpdateBuyingStep(
    $filter: ModelSubscriptionBuyingStepFilterInput
  ) {
    onUpdateBuyingStep(filter: $filter) {
      id
      date
      name
      description
      createdAt
      updatedAt
      homeBuyingProcessId
    }
  }
`;
export const onDeleteBuyingStep = /* GraphQL */ `
  subscription OnDeleteBuyingStep(
    $filter: ModelSubscriptionBuyingStepFilterInput
  ) {
    onDeleteBuyingStep(filter: $filter) {
      id
      date
      name
      description
      createdAt
      updatedAt
      homeBuyingProcessId
    }
  }
`;
export const onCreateInterest = /* GraphQL */ `
  subscription OnCreateInterest(
    $filter: ModelSubscriptionInterestFilterInput
    $owner: String
  ) {
    onCreateInterest(filter: $filter, owner: $owner) {
      userId
      propertyId
      interest
      amount
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateInterest = /* GraphQL */ `
  subscription OnUpdateInterest(
    $filter: ModelSubscriptionInterestFilterInput
    $owner: String
  ) {
    onUpdateInterest(filter: $filter, owner: $owner) {
      userId
      propertyId
      interest
      amount
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteInterest = /* GraphQL */ `
  subscription OnDeleteInterest(
    $filter: ModelSubscriptionInterestFilterInput
    $owner: String
  ) {
    onDeleteInterest(filter: $filter, owner: $owner) {
      userId
      propertyId
      interest
      amount
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount(
    $filter: ModelSubscriptionAccountFilterInput
    $author: String
  ) {
    onCreateAccount(filter: $filter, author: $author) {
      userId
      offeringId
      author
      offering {
        id
        cmAddress
        home {
          id
          name
          status
          address
          city
          state
          zipCode
          numBeds
          numBaths
          squareFt
          totalSupply
          remainingSupply
          description
          images
          irr
          createdAt
          updatedAt
          homeCoordinateId
          homeMarketId
          homeFinancialProjectionId
        }
        status
        totalSupply
        remainingSupply
        pricePerShare
        minInvestment
        maxInvestment
        projectedValue
        nftArt
        date
        groups {
          nextToken
        }
        comments {
          nextToken
        }
        likesDislikes {
          nextToken
        }
        views
        createdAt
        updatedAt
        offeringHomeId
      }
      transactions {
        items {
          id
          author
          amount
          status
          type
          wallet
          createdAt
          updatedAt
          accountTransactionsUserId
          accountTransactionsOfferingId
        }
        nextToken
      }
      balance
      createdAt
      updatedAt
      accountOfferingId
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount(
    $filter: ModelSubscriptionAccountFilterInput
    $author: String
  ) {
    onUpdateAccount(filter: $filter, author: $author) {
      userId
      offeringId
      author
      offering {
        id
        cmAddress
        home {
          id
          name
          status
          address
          city
          state
          zipCode
          numBeds
          numBaths
          squareFt
          totalSupply
          remainingSupply
          description
          images
          irr
          createdAt
          updatedAt
          homeCoordinateId
          homeMarketId
          homeFinancialProjectionId
        }
        status
        totalSupply
        remainingSupply
        pricePerShare
        minInvestment
        maxInvestment
        projectedValue
        nftArt
        date
        groups {
          nextToken
        }
        comments {
          nextToken
        }
        likesDislikes {
          nextToken
        }
        views
        createdAt
        updatedAt
        offeringHomeId
      }
      transactions {
        items {
          id
          author
          amount
          status
          type
          wallet
          createdAt
          updatedAt
          accountTransactionsUserId
          accountTransactionsOfferingId
        }
        nextToken
      }
      balance
      createdAt
      updatedAt
      accountOfferingId
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount(
    $filter: ModelSubscriptionAccountFilterInput
    $author: String
  ) {
    onDeleteAccount(filter: $filter, author: $author) {
      userId
      offeringId
      author
      offering {
        id
        cmAddress
        home {
          id
          name
          status
          address
          city
          state
          zipCode
          numBeds
          numBaths
          squareFt
          totalSupply
          remainingSupply
          description
          images
          irr
          createdAt
          updatedAt
          homeCoordinateId
          homeMarketId
          homeFinancialProjectionId
        }
        status
        totalSupply
        remainingSupply
        pricePerShare
        minInvestment
        maxInvestment
        projectedValue
        nftArt
        date
        groups {
          nextToken
        }
        comments {
          nextToken
        }
        likesDislikes {
          nextToken
        }
        views
        createdAt
        updatedAt
        offeringHomeId
      }
      transactions {
        items {
          id
          author
          amount
          status
          type
          wallet
          createdAt
          updatedAt
          accountTransactionsUserId
          accountTransactionsOfferingId
        }
        nextToken
      }
      balance
      createdAt
      updatedAt
      accountOfferingId
    }
  }
`;
export const onCreateTransaction = /* GraphQL */ `
  subscription OnCreateTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
    $author: String
  ) {
    onCreateTransaction(filter: $filter, author: $author) {
      id
      signatureId
    }
  }
`;
export const onUpdateTransaction = /* GraphQL */ `
  subscription OnUpdateTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
    $author: String
  ) {
    onUpdateTransaction(filter: $filter, author: $author) {
      id
      author
      amount
      status
      type
      wallet
      createdAt
      updatedAt
      accountTransactionsUserId
      accountTransactionsOfferingId
    }
  }
`;
export const onDeleteTransaction = /* GraphQL */ `
  subscription OnDeleteTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
    $author: String
  ) {
    onDeleteTransaction(filter: $filter, author: $author) {
      id
      author
      amount
      status
      type
      wallet
      createdAt
      updatedAt
      accountTransactionsUserId
      accountTransactionsOfferingId
    }
  }
`;
export const onCreateNAWhitelist = /* GraphQL */ `
  subscription OnCreateNAWhitelist(
    $filter: ModelSubscriptionNAWhitelistFilterInput
  ) {
    onCreateNAWhitelist(filter: $filter) {
      offeringId
      whitelist
      count
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNAWhitelist = /* GraphQL */ `
  subscription OnUpdateNAWhitelist(
    $filter: ModelSubscriptionNAWhitelistFilterInput
  ) {
    onUpdateNAWhitelist(filter: $filter) {
      offeringId
      whitelist
      count
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNAWhitelist = /* GraphQL */ `
  subscription OnDeleteNAWhitelist(
    $filter: ModelSubscriptionNAWhitelistFilterInput
  ) {
    onDeleteNAWhitelist(filter: $filter) {
      offeringId
      whitelist
      count
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInvestTracking = /* GraphQL */ `
  subscription OnCreateInvestTracking(
    $filter: ModelSubscriptionInvestTrackingFilterInput
    $owner: String
  ) {
    onCreateInvestTracking(filter: $filter, owner: $owner) {
      id
      userId
      propertyID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateInvestTracking = /* GraphQL */ `
  subscription OnUpdateInvestTracking(
    $filter: ModelSubscriptionInvestTrackingFilterInput
    $owner: String
  ) {
    onUpdateInvestTracking(filter: $filter, owner: $owner) {
      id
      userId
      propertyID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteInvestTracking = /* GraphQL */ `
  subscription OnDeleteInvestTracking(
    $filter: ModelSubscriptionInvestTrackingFilterInput
    $owner: String
  ) {
    onDeleteInvestTracking(filter: $filter, owner: $owner) {
      id
      userId
      propertyID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateLike = /* GraphQL */ `
  subscription OnCreateLike(
    $filter: ModelSubscriptionLikeFilterInput
    $owner: String
  ) {
    onCreateLike(filter: $filter, owner: $owner) {
      userId
      propertyId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateLike = /* GraphQL */ `
  subscription OnUpdateLike(
    $filter: ModelSubscriptionLikeFilterInput
    $owner: String
  ) {
    onUpdateLike(filter: $filter, owner: $owner) {
      userId
      propertyId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteLike = /* GraphQL */ `
  subscription OnDeleteLike(
    $filter: ModelSubscriptionLikeFilterInput
    $owner: String
  ) {
    onDeleteLike(filter: $filter, owner: $owner) {
      userId
      propertyId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateNews = /* GraphQL */ `
  subscription OnCreateNews($filter: ModelSubscriptionNewsFilterInput) {
    onCreateNews(filter: $filter) {
      id
      title
      source
      image
      url
      date
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNews = /* GraphQL */ `
  subscription OnUpdateNews($filter: ModelSubscriptionNewsFilterInput) {
    onUpdateNews(filter: $filter) {
      id
      title
      source
      image
      url
      date
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNews = /* GraphQL */ `
  subscription OnDeleteNews($filter: ModelSubscriptionNewsFilterInput) {
    onDeleteNews(filter: $filter) {
      id
      title
      source
      image
      url
      date
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onCreateUser(filter: $filter, owner: $owner) {
      id
      country
      state
      firstName
      lastName
      email
      isAccredited
      approved
      kycStatus
      accreditedStatus
      wallet
      walletType
      accreditationDoc
      owner
      status
      accreditationForm {
        id
        educationLevel
        educationName
        employmentStatus
        employerRole
        investmentObjective
        accreditationClaim
        accreditationType
        riskConsent
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userAccreditationFormId
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onUpdateUser(filter: $filter, owner: $owner) {
      id
      country
      state
      firstName
      lastName
      email
      isAccredited
      approved
      kycStatus
      accreditedStatus
      wallet
      walletType
      accreditationDoc
      owner
      status
      accreditationForm {
        id
        educationLevel
        educationName
        employmentStatus
        employerRole
        investmentObjective
        accreditationClaim
        accreditationType
        riskConsent
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userAccreditationFormId
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onDeleteUser(filter: $filter, owner: $owner) {
      id
      country
      state
      firstName
      lastName
      email
      isAccredited
      approved
      kycStatus
      accreditedStatus
      wallet
      walletType
      accreditationDoc
      owner
      status
      accreditationForm {
        id
        educationLevel
        educationName
        employmentStatus
        employerRole
        investmentObjective
        accreditationClaim
        accreditationType
        riskConsent
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userAccreditationFormId
    }
  }
`;
export const onCreatePurchaseGroup = /* GraphQL */ `
  subscription OnCreatePurchaseGroup(
    $filter: ModelSubscriptionPurchaseGroupFilterInput
  ) {
    onCreatePurchaseGroup(filter: $filter) {
      id
      date
      members
      createdAt
      updatedAt
      offeringGroupsId
    }
  }
`;
export const onUpdatePurchaseGroup = /* GraphQL */ `
  subscription OnUpdatePurchaseGroup(
    $filter: ModelSubscriptionPurchaseGroupFilterInput
  ) {
    onUpdatePurchaseGroup(filter: $filter) {
      id
      date
      members
      createdAt
      updatedAt
      offeringGroupsId
    }
  }
`;
export const onDeletePurchaseGroup = /* GraphQL */ `
  subscription OnDeletePurchaseGroup(
    $filter: ModelSubscriptionPurchaseGroupFilterInput
  ) {
    onDeletePurchaseGroup(filter: $filter) {
      id
      date
      members
      createdAt
      updatedAt
      offeringGroupsId
    }
  }
`;
export const onCreateAccreditationForm = /* GraphQL */ `
  subscription OnCreateAccreditationForm(
    $filter: ModelSubscriptionAccreditationFormFilterInput
  ) {
    onCreateAccreditationForm(filter: $filter) {
      id
      educationLevel
      educationName
      employmentStatus
      employerRole
      investmentObjective
      accreditationClaim
      accreditationType
      riskConsent
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAccreditationForm = /* GraphQL */ `
  subscription OnUpdateAccreditationForm(
    $filter: ModelSubscriptionAccreditationFormFilterInput
  ) {
    onUpdateAccreditationForm(filter: $filter) {
      id
      educationLevel
      educationName
      employmentStatus
      employerRole
      investmentObjective
      accreditationClaim
      accreditationType
      riskConsent
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAccreditationForm = /* GraphQL */ `
  subscription OnDeleteAccreditationForm(
    $filter: ModelSubscriptionAccreditationFormFilterInput
  ) {
    onDeleteAccreditationForm(filter: $filter) {
      id
      educationLevel
      educationName
      employmentStatus
      employerRole
      investmentObjective
      accreditationClaim
      accreditationType
      riskConsent
      createdAt
      updatedAt
    }
  }
`;
