import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import solana from "../static/solana.svg";

type Event = "connect" | "disconnect";

interface Phantom {
  on: (event: Event, callback: () => void) => void;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
}

const ConnectToPhantom = ({
  callback,
  text,
}: {
  callback: any;
  text: string;
}) => {
  const [phantom, setPhantom] = useState<Phantom | null>(null);

  useEffect(() => {
    if ("solana" in window) {
      setPhantom(window["solana"]);
    }
  }, []);

  const [connected, setConnected] = useState(false);

  useEffect(() => {
    phantom?.on("connect", () => {
      setConnected(true);
    });

    phantom?.on("disconnect", () => {
      setConnected(false);
    });
  }, [phantom]);

  const connectHandler = async () => {
    await phantom?.connect();
    callback();
  };

  const disconnectHandler = () => {
    phantom?.disconnect();
  };

  if (phantom) {
    if (connected) {
      return (
        <Button
          fullWidth
          onClick={disconnectHandler}
          variant="contained"
          sx={{ textTransform: "none", backgroundColor: "#3300CC" }}
          startIcon={<img src={solana} width="20px" alt="Solana logo" />}
        >
          Disconnect from Phantom
        </Button>
      );
    }

    return (
      <Button
        fullWidth
        onClick={connectHandler}
        variant="contained"
        sx={{ textTransform: "none", backgroundColor: "#3300CC" }}
        startIcon={<img src={solana} width="20px" alt="Solana logo" />}
      >
        {text}
      </Button>
    );
  }

  return (
    <Button
      fullWidth
      href="https://phantom.app/"
      variant="contained"
      sx={{ textTransform: "none", backgroundColor: "#3300CC" }}
      startIcon={<img src={solana} width="20px" alt="Solana logo" />}
      target="_blank"
      rel="noreferrer"
    >
      Get Phantom
    </Button>
  );
};

export default ConnectToPhantom;
